import store from "storejs";

export const LOCAL_STORAGE_KEY = "da.dmcmicroeditor.preferences";

export const DEFAULT_PREFERENCES = {
    input_id: null,      // web midi port ID
    output_id: null,     // web midi port ID
    colors: false,
    darkMode: null      // default to light mode
}

export function loadPreferences() {
    const s = store.get(LOCAL_STORAGE_KEY);
    return Object.assign({}, DEFAULT_PREFERENCES, s ? JSON.parse(s) : {});
}

export function savePreferences(options = {}) {
    const currentPreferences = loadPreferences();
    const newPreferences = Object.assign({}, currentPreferences, options);
    store(LOCAL_STORAGE_KEY, JSON.stringify(newPreferences));
}