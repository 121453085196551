import {getGlobalParameter} from "../model";
import {stores} from "../stores";

function checkCondition(condition: any) {
    let param = getGlobalParameter(condition.param);
    if (param) {
        let value = stores.memory.getValue(param);
        let op = condition.op ?? "eq";
        // console.log("param.conditions", cond.value, op, condParamValue);
        switch (op.toLowerCase()) {
            case "eq":
                if (value !== condition.value) {
                    return false;
                }
                break;
            case "ne":
                if (value === condition.value) {
                    return false;
                }
                break;
            case "gt":
                if (value <= condition.value) {
                    return false;
                }
                break;
            case "lt":
                if (value >= condition.value) {
                    return false;
                }
                break;
            default:
                console.warn("condition param operator not supported ", condition)
        }
    } else {
        console.warn("condition param not found", condition);
    }
    return true;
}

export function conditionsOk(item: any): boolean {

    if ("condition" in item) {
        //FIXME: allow condition on preset parameter too
        if (!checkCondition(item.condition)) {
            return false;
        }
    }

    if ("conditions" in item) {
        for (let cond of item.conditions) {
            if (!checkCondition(cond)) {
                return false;
            }
        }
    }

    return true;

}