import DEVICES from "./devices.json";

export interface DisplayStyleMapper {
    (index: number): string;
}

function createOptions(min: number, max: number, mapper: DisplayStyleMapper) {
    const opts = [...Array(max - min + 1)].map((i, j) => {
        return <option key={j + min} value={j + min}>{mapper(j + min)}</option>;
    });
    opts.push(<option key={max+1} value={max+1}>Don't change</option>);
    return opts;
}

function pad(v: number, n=2): string {
    return v.toString().padStart(n, '0');
}

export const DISPLAY_STYLES_INFO = [
    //  0	Zero Based	0-127
    "Zero based",
    //  1	One Based	1-128
    "One based",
    //  2	2 digits plus A or B	00A - 99B
    "00A..99B",
    //  3	2 digits plus A, B, or C	00A - 99C
    "00A..99C",
    //  4	2 digits plus A, B, or C / one-based	01A-99C
    "01A..99C",
    //  5	MIDI notes, still zero-based	0-127
    "MIDI Note",
    //  6	Source Audio Hub, zero-based	0-127
    "Source Audio",
    //  7	Toggle	OFF or ON
    "Toggle",
    //  8	Hologram Dream Sequence	U0-11, F0-11, S0-11
    "Hologram Dream Sequence, USER 1-12, FACTORY 1-12, SAVED 1-12",
    //  9	Chase Bliss, zero based but special cases for 0-1-2	M, RT, LT, 3-122
    "Chase Bliss, 1=left, 2=middle, 3=right, 4-122",
    // 10	2 digits plus A-D	0A - 99D
    "00A..99D",
    // 11	Jackson Audio Golden Boy	0-0 through 4-4
    "Jackson Audio Golden, 0-0 through 4-4",
    // 12	Jackson Audio Bloom, others	0-7, B - E
    "Jackson Audio Bloom, others, 0-7, B-E",
];

export const DISPLAY_STYLE_MAPPERS = [
    //  0	Zero Based	0-127
    (index: number) => {
        return `${index}`;
    },
    //  1	One Based	1-128
    (index: number) => {
        return `${index+1}`;
    },
    //  2	2 digits plus A or B	00A - 99B
    (index: number) => {
        let digits = pad(index >> 1);
        let alpha = String.fromCharCode('A'.charCodeAt(0) + index % 2);
        return `${digits}${alpha}`;
    },
    //  3	2 digits plus A, B, or C	00A - 99C
    (index: number) => {
        let digits = pad(Math.floor(index / 3));
        let alpha = String.fromCharCode('A'.charCodeAt(0) + index % 3);
        return `${digits}${alpha}`;
    },
    //  4	2 digits plus A, B, or C / one-based	01A-99C
    (index: number) => {
        let digits = pad(Math.floor(index / 3) + 1);
        let alpha = String.fromCharCode('A'.charCodeAt(0) + index % 3);
        return `${digits}${alpha}`;
    },
    //  5	MIDI notes, still zero-based	0-127
    (index: number) => {
        return `MIDI NOTE ${index}`;
    },
    //  6	Source Audio Hub, zero-based	0-127
    (index: number) => {
        return `${index}`;
    },
    //  7	Toggle	OFF or ON
    (index: number) => {
        //TODO
        return `${index === 0 ? 'OFF' : 'ON'}`;
        // return `Toggle: ${index === 0 ? 'OFF' : 'ON'}`;
    },
    //  8	Hologram Dream Sequence	U0-11, F0-11, S0-11
    (index: number) => {
        // console.log("create option for hologram", index);
        let digits = Math.floor(index % 12) + 1;
        let alpha;
        if (index < 12)
            alpha = 'USER'
        else if (index < 24)
            alpha = 'FACTORY'
        else
            alpha = 'SAVED';
        return `${alpha} ${digits}`;
    },
    //  9	Chase Bliss, zero based but special cases for 0-1-2	M, RT, LT, 3-122
    (index: number) => {
        switch (index) {
            case 0: return '0 (middle, "live mode")';
            case 1: return '1 (right)';
            case 2: return '2 (left)';
            default: return `${index}`;
        }
    },
    // 10	2 digits plus A-D	0A - 99D
    (index: number) => {
        let digits = pad(Math.floor(index / 4));
        let alpha = String.fromCharCode('A'.charCodeAt(0) + index % 4);
        return `${digits}${alpha}`;
    },
    // 11	Jackson Audio Golden Boy	0-0 through 4-4
    (index: number) => {
        //TODO
        return `${index}`;
    },
    // 12	Jackson Audio Bloom, others	0-7, B - E
    (index: number) => {
        //TODO
        return `${index}`;
    },
    // 13, Chroma Console
    (index: number) => {
        let digits = Math.floor(index / 4) + 1; // Calculate digits from 1 to 20
        let alpha = String.fromCharCode(65 + (index % 4)); // Calculate alpha from 'A' to 'D'
        return `${digits}${alpha}`;
    }
];

export const getDeviceDisplayStyleOptions = (deviceNum: number) => {
    const device = DEVICES[deviceNum];
    if (!device) {
        console.warn("getPrgOptionsForDevice: device not found", deviceNum);
        return [];
    }
    return createOptions(device.presetMin, device.presetMax, DISPLAY_STYLE_MAPPERS[device.displayStyle]);
}
