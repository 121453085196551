import React, { useEffect, useState, useCallback, useRef } from "react";
import { observer } from "mobx-react-lite";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { stores } from "../../stores";
import { GLOBAL_MEMORY } from "../../stores/memoryStore";
import { ParameterType } from "../../model";
import { getGlobalParameterStateStyle, getPresetParameterStateStyle } from "../../utils/styling";

interface ParameterProps {
    parameter: ParameterType;
    presetNumber?: number;
}

export const InputNumber = observer(({ parameter, presetNumber = GLOBAL_MEMORY }: ParameterProps) => {
    const [input, setInput] = useState(stores.memory.getValue(parameter, presetNumber).toString(10));
    const paramValue = stores.memory.getValue(parameter, presetNumber);
    const timeoutRef = useRef<NodeJS.Timeout | null>(null);

    useEffect(() => {
        setInput(stores.memory.getValue(parameter, presetNumber).toString(10));
    }, [paramValue, presetNumber, parameter]);

    const isValid = useCallback((s: string) => {
        const n = parseInt(s, 10);
        return /^\d+$/.test(s) && !isNaN(n) && (n >= parameter.min) && (n <= parameter.max);
    }, [parameter.min, parameter.max]);

    const debouncedSetValue = useCallback((value: string) => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
        timeoutRef.current = setTimeout(() => {
            if (isValid(value)) {
                stores.memory.setValue(parameter, parseInt(value, 10), presetNumber);
                stores.midi.save(presetNumber);
            }
        }, 1000);
    }, [isValid, parameter, presetNumber]);

    const handleChange = useCallback((e: React.FormEvent<HTMLInputElement>) => {
        const v = e.currentTarget.value;
        setInput(v);
        stores.midi.cancelSave();
        debouncedSetValue(v);
    }, [debouncedSetValue]);

    if (!parameter) {
        return <div>PARAMETER NOT FOUND</div>;
    }

    const style = parameter.type === "global"
        ? getGlobalParameterStateStyle(parameter.id)
        : getPresetParameterStateStyle(parameter.id, presetNumber);

    return (
        <div className="param-input input-number">
            <div className={`state ${style}`}>
                <input type="text" size={3} value={input} onChange={handleChange} />
            </div>
            {!isValid(input) && <span className="ml-10 red"><FontAwesomeIcon icon={faExclamationCircle} /></span>}
            <div className="param-help">{parameter.min}...{parameter.max}, default: {parameter.default}</div>
        </div>
    );
});