import {observer} from "mobx-react-lite";
import {useEffect, useState} from "react";
import {stores} from "../../stores";
// import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
// import {faExclamationCircle} from "@fortawesome/free-solid-svg-icons";
import {GLOBAL_MEMORY} from "../../stores/memoryStore";
import {ParameterType} from "../../model";
import {getGlobalParameterStateStyle, getPresetParameterStateStyle} from "../../utils/styling";

interface ParameterProps {
    parameter: ParameterType;
    presetNumber?: number;
}

export const InputSlider = observer(({parameter, presetNumber = GLOBAL_MEMORY}: ParameterProps) => {

    // console.log("render Number", parameterId, presetNumber);

    const [input, setInput] = useState(stores.memory.getValue(parameter, presetNumber).toString(10));

    const paramValue = stores.memory.getValue(parameter, presetNumber);

    useEffect(() => {
        setInput(stores.memory.getValue(parameter, presetNumber).toString(10));
    }, [paramValue, presetNumber, parameter]);

    // function isValid(s: string) {
    //     const n = parseInt(s, 10);
    //     return /^\d+$/.test(s) && !isNaN(n) && (n >= parameter.min) && (n <= parameter.max);
    // }

    function handleChange(e: React.FormEvent<HTMLInputElement>) {
        const v = e.currentTarget.value;
        setInput(v);
        stores.midi.cancelSave();
        // if (!isValid(v)) return;
        stores.memory.setValue(parameter, parseInt(v, 10), presetNumber);
        stores.midi.save(presetNumber);
    }

    function commitValue() {
        // if (!isValid(input)) return;
        // stores.memory.save(presetNumber);
    }

    if (!parameter) {
        return <div>PARAMETER NOT FOUND</div>;
    }

    const style = parameter.type === "global" ?
        getGlobalParameterStateStyle(parameter.id) :
        getPresetParameterStateStyle(parameter.id, presetNumber);

    return (
        // <div className="param-input input-number">
        //     <div className={`state ${style}`}>
        //         <input type="text" size={3} value={input} onChange={handleChange} onBlur={commitValue} />
        //     </div>
        //     {!isValid(input) && <span className="ml-10 red"><FontAwesomeIcon icon={faExclamationCircle} /></span>}
        //     <div className="param-help">{parameter.min}...{parameter.max}, default: {parameter.default}</div>
        // </div>);
        <div className="param-input input-slider">
            <div className={`state ${style}`}>
                <input list="ticks" className="slider width-120" type="range" min={parameter.min} max={parameter.max} step="1" value={input} onChange={handleChange} />
                {/*<input type="text" id="textInput" value={input} />*/}
                <input type="text" size={3} value={input} onChange={handleChange} onBlur={commitValue} />
            </div>
        </div>);
});
