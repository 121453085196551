import {observer} from "mobx-react-lite";
import {SortableItem} from "./SortableItem";
import React from "react";

export const SortableSelectorWrapper = observer(({presetNumber, itemKey, id, overed, srcElement, ctrlKeyPressed}) => {

    console.log("SortableSelectorWrapper", presetNumber)

    return (
        <div className={`sortable-selector-wrapper`}>
        <div className="preset-number">{presetNumber}</div>
        <SortableItem
            presetNumber={presetNumber}
            key={itemKey}
            id={id}
            overed={overed}
            srcElement={srcElement}
            ctrlKeyPressed={ctrlKeyPressed} />
        </div>
    );

});
