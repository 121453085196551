import React from 'react';
import { observer } from "mobx-react-lite";
import { MidiSupportWarning } from './components/midi/MidiSupportWarning';
import { Header } from "./components/Header";
import { Tabs } from "./components/Tabs";
import { stores } from "./stores";
import { Presets } from "./components/Presets";
import { ImportExport } from "./components/ImportExport";
import { Global } from "./components/Global";
import { Overview } from "./components/overview/Overview";
import { PresetsGrid } from "./components/PresetsGrid";
import { Help } from "./components/Help";
import './App.css';

// export const App = observer(() => {
//     const renderContent = () => {
//         switch (stores.state.activeTab) {
//             case 'presets': return <Presets />;
//             case 'presets-grid': return <PresetsGrid />;
//             case 'global': return <Global />;
//             case 'import-export': return <ImportExport />;
//             case 'help': return <Help />;
//             case 'overview': return <Overview />;
//             default: return null;
//         }
//     };

//     return (
//         <div className="app-container">
//             <MidiSupportWarning />
//             <Header />
//             <Tabs />
//             <div className="content-container">
//                 {renderContent()}
//             </div>
//         </div>
//     );
// });

export const App = observer(() => {

    return (
        <>
            <MidiSupportWarning />
            <Header />
            <Tabs />
            {stores.state.activeTab === 'presets' && <Presets />}
            {stores.state.activeTab === 'presets-grid' && <PresetsGrid />}
            {stores.state.activeTab === 'global' && <Global />}
            {stores.state.activeTab === 'import-export' && <ImportExport />}
            {stores.state.activeTab === 'help' && <Help />}
            {stores.state.activeTab === 'overview' && <Overview />}
            {/*{(process.env.REACT_APP_ENV === "dev") && (stores.state.activeTab === 'lab') && <Lab />}*/}
            {/*{process.env.REACT_APP_ENV && <Dump />}*/}
        </>
    );

});