import {observer} from "mobx-react-lite";
import React, {useRef} from "react";
import {stores} from "../stores";
import {readFiles} from "../utils/files";
import {NUMBER_OF_PRESETS_SLOTS} from "../stores/memoryStore";
import "./ImportExport.css";

export const ImportExport = observer(() => {

    const inputOpenFileRef = useRef(null);

    async function onFileSelection(e: any) {
        await readFiles(e.target.files);
    }

    function loadFile() {
        if (inputOpenFileRef) {
            // @ts-ignore
            inputOpenFileRef.current.click()
        }
    }

    function exportAsFile(data: Uint8Array, basename: string) {

        let url = window.URL.createObjectURL(new Blob([data], {type: "application/octet-stream"}));

        let now = new Date();
        let timestamp =
            now.getUTCFullYear() + "-" +
            ("0" + (now.getUTCMonth() + 1)).slice(-2) + "-" +
            ("0" + now.getUTCDate()).slice(-2) + "-" +
            ("0" + now.getUTCHours()).slice(-2) + "" +
            ("0" + now.getUTCMinutes()).slice(-2) + "" +
            ("0" + now.getUTCSeconds()).slice(-2);
        let filename = basename + '.' + timestamp;

        let shadowLink = document.createElement("a");
        shadowLink.download = filename + ".syx";
        shadowLink.style.display = "none";
        shadowLink.href = url;

        document.body.appendChild(shadowLink);
        shadowLink.click();
        document.body.removeChild(shadowLink);

        setTimeout(function() {
            return window.URL.revokeObjectURL(url);
        }, 1000);
    }

    function downloadConfiguration() {
        exportAsFile(new Uint8Array(stores.memory.global), 'dmc-micro.configuration');
    }

    function downloadPresets() {
        const data = [];
        for (let i = 0; i < NUMBER_OF_PRESETS_SLOTS; i++) {
            for (let j=0; j < stores.memory.presets[i].length ; j++) {
                data.push(stores.memory.presets[i][j]);
            }
        }
        exportAsFile(new Uint8Array(data), 'dmc-micro.presets');
    }

    function saveGlobal() {
        stores.midi.saveGlobal();
    }

    function savePresets() {
        stores.midi.saveAllPresets();
    }

    return (
        <div className="import-export main-content p-20">
            <div className="">
                <div className="bold mb-10">
                    Import global configuration or presets
                </div>
                <div>
                    <input ref={inputOpenFileRef} type="file" multiple={true} style={{display:"none"}} onChange={onFileSelection} />
                    <button type="button" onClick={loadFile}>Load sysex file</button>
                </div>
                <div className="mt-10 row">
                    <div>
                        {stores.state.importedPresets} presets imported.
                    </div>
                    <div className="ml-10">
                        {(stores.midi.deviceFound && stores.state.importedPresets > 0) && <button type="button" onClick={savePresets}>Save in DMC.micro</button>}
                    </div>
                    {(stores.midi.savingPresetNumber >= 0) && <div className="ml-10 midi-writing">
                        saving preset #{stores.midi.savingPresetNumber}
                    </div>}
                </div>
                <div className="mt-10 row">
                    <div>
                        {stores.state.importedConfiguration} configuration sysex imported.
                    </div>
                    <div className="ml-10">
                        {(stores.midi.deviceFound &&  stores.state.importedConfiguration > 0) && <button type="button" onClick={saveGlobal}>Save in DMC.micro</button>}
                    </div>
                </div>
            </div>
            <div className="mt-30">
                <div className="bold mb-10">
                    Export global configuration
                </div>
                <div>
                    <button type="button" onClick={downloadConfiguration}>Download configuration as sysex file</button>
                </div>
            </div>
            <div className="mt-30 mb-20">
                <div className="bold mb-10">
                    Export presets
                </div>
                <div>
                    <button type="button" onClick={downloadPresets}>Download presets as sysex file</button>
                </div>
            </div>
        </div>
    );
});
