import {observer} from "mobx-react-lite";
import React from "react";
import {Parameter} from "./parameter/Parameter";
import {stores} from "../stores";
import {PresetDevice} from "./PresetDevice";
import {getPresetParameter} from "../model";
import "./Preset.css";

export const Preset = observer(() => {

    // 6 bits.  B00 YA YL YR XA XL XR
    function isBitSet(parameterId: string, mask: number) {
        // console.log("updateUtilX", value);
        const parameter = getPresetParameter(parameterId);
        return (stores.memory.getValue(parameter, presetNumber) & mask) > 0;
    }

    // 6 bits.  B00 YA YL YR XA XL XR
    function toggleBit(parameterId: string, mask: number) {
        const parameter = getPresetParameter(parameterId);
        const v = stores.memory.getValue(parameter, presetNumber);
        stores.memory.setValue(parameter, v ^ mask, presetNumber);
        stores.midi.save(presetNumber);
    }

    const presetNumber = stores.state.activePreset;
    const numberOfDevices = stores.memory.getGlobalParamValue("DEVICES");

    const util_load = stores.memory.getGlobalParamValue("UTIL_LOAD");
    const util_load_x = util_load & 0x01;
    const util_load_y = util_load & 0x02;
    const util_load_jack = util_load & 0x04;

    const util_x = stores.memory.getGlobalParamValue("UTIL_MOD_X");
    const util_x_left_type = stores.memory.getGlobalParamValue("UT_X_L_TYP");
    const util_x_right_type = stores.memory.getGlobalParamValue("UT_X_R_TYP");
    const util_x_alt_type = stores.memory.getGlobalParamValue("UT_X_A_TYP");

    const util_y = stores.memory.getGlobalParamValue("UTIL_MOD_Y");
    const util_y_left_type = stores.memory.getGlobalParamValue("UT_Y_L_TYP");
    const util_y_right_type = stores.memory.getGlobalParamValue("UT_Y_R_TYP");
    const util_y_alt_type = stores.memory.getGlobalParamValue("UT_Y_A_TYP");

    const bitXR = 0b00000010;
    const bitXL = 0b00000001;
    const bitXA = 0b00000100;
    const bitYR = 0b00010000;
    const bitYL = 0b00001000;
    const bitYA = 0b00100000;
    const bitTip = 0b00000001;
    const bitRing = 0b00000010;

    return (
        <div className="preset">

            <div className="title">
                Preset #{presetNumber}
            </div>

            <Parameter parameterId={"NAME"} presetNumber={presetNumber} />
            <Parameter parameterId={"TEMPO"} presetNumber={presetNumber} />
            <Parameter parameterId={"TAPDIV"} presetNumber={presetNumber} />

            {numberOfDevices > 0 && <PresetDevice deviceLetter="A" />}
            {numberOfDevices > 1 && <PresetDevice deviceLetter="B" />}
            {numberOfDevices > 2 && <PresetDevice deviceLetter="C" />}
            {numberOfDevices > 3 && <PresetDevice deviceLetter="D" />}

            <div className="title2">
                Utils Mode X, Y &amp; Jack
            </div>

            <div className="group-info mb-10rem">
                See the UTIL LOAD parameter in the global configuration to enable/disable these features.
            </div>

            <div className="preset-param-group mb-10rem">
                {!!util_load_x && (util_x > 0) &&
                <div className="parameter row">
                    <div>
                        UTIL X state recall:
                    </div>
                    <div className={`ml-10 ${util_x_left_type !== 1 ? 'light-text' : ''}`}>
                        <input type="checkbox" disabled={(util_x_left_type !== 1)}
                               checked={isBitSet("UTIL1", bitXL)} onChange={() => toggleBit("UTIL1", bitXL)} /> Left
                    </div>
                    <div className={`ml-20 ${util_x_alt_type !== 1 ? 'light-text' : ''}`}>
                        <input type="checkbox" disabled={(util_x_alt_type !== 1)}
                               checked={isBitSet("UTIL1", bitXA)} onChange={() => toggleBit("UTIL1", bitXA)}/> Alt
                    </div>
                    <div className={`ml-20 ${util_x_right_type !== 1 ? 'light-text' : ''}`}>
                        <input type="checkbox" disabled={(util_x_right_type !== 1)}
                               checked={isBitSet("UTIL1", bitXR)} onChange={() => toggleBit("UTIL1", bitXR)}/> Right
                    </div>
                </div>}
                {!util_load_x &&
                <div className="parameter light-text mb-10rem">
                    UTIL X is disabled in the global config.
                </div>}
            </div>

            <div className="preset-param-group mb-10rem">
                {!!util_load_y && (util_y > 0) &&
                <div className="parameter row">
                    <div>
                        UTIL Y state recall:
                    </div>
                    <div className={`ml-10 ${util_y_left_type !== 1 ? 'light-text' : ''}`}>
                        <input type="checkbox" disabled={(util_y_left_type !== 1)}
                               checked={isBitSet("UTIL1", bitYL)} onChange={() => toggleBit("UTIL1", bitYL)} /> Left
                    </div>
                    <div className={`ml-20 ${util_y_alt_type !== 1 ? 'light-text' : ''}`}>
                        <input type="checkbox" disabled={(util_y_alt_type !== 1)}
                               checked={isBitSet("UTIL1", bitYA)} onChange={() => toggleBit("UTIL1", bitYA)} /> Alt
                    </div>
                    <div className={`ml-20 ${util_y_right_type !== 1 ? 'light-text' : ''}`}>
                        <input type="checkbox" disabled={(util_y_right_type !== 1)}
                               checked={isBitSet("UTIL1", bitYR)} onChange={() => toggleBit("UTIL1", bitYR)} /> Right
                    </div>
                </div>}
                {!util_load_y &&
                <div className="parameter light-text">
                    UTIL Y is disabled in the global config.
                </div>}
            </div>

            <div className="preset-param-group mb-10rem">
                {!!util_load_jack &&
                <div className="parameter row">
                    <div>
                        UTIL JACK state recall:
                    </div>
                    {!!util_load_jack &&
                    <div className="row">
                        <div className="ml-10">
                            <input type="checkbox"
                                   checked={isBitSet("UTIL2", bitTip)} onChange={() => toggleBit("UTIL2", bitTip)} /> Jack Tip
                        </div>
                        <div className="ml-20">
                            <input type="checkbox"
                                   checked={isBitSet("UTIL2", bitRing)} onChange={() => toggleBit("UTIL2", bitRing)} /> Jack Ring
                        </div>
                    </div>}
                </div>}
                {!util_load_jack &&
                <div className="parameter light-text">
                    UTIL JACK is disabled in the global config.
                </div>}
            </div>

        </div>
    );

});
