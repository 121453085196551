import React, { FormEvent, useState, useEffect } from 'react';
import { observer } from "mobx-react-lite";
import { stores } from "../../stores";
import "./MidiPortsSelect.css";
import { Port } from "../../stores/midiStore";

export const MidiPortsSelect = observer(() => {
    const [inputColor, setInputColor] = useState('');
    const [outputColor, setOutputColor] = useState('');

    useEffect(() => {
        const inputTimer = setInterval(() => {
            setInputColor(stores.midi.receiving ? 'bg-success' : '');
        }, 100);

        const outputTimer = setInterval(() => {
            setOutputColor(stores.midi.saving ? 'bg-success' : '');
        }, 100);

        return () => {
            clearInterval(inputTimer);
            clearInterval(outputTimer);
        };
    }, []);

    function handleInSelection(e: FormEvent<HTMLSelectElement>) {
        e.preventDefault();
        const v = e.currentTarget.value;
        stores.midi.useInput(v, true);
    }

    function handleOutSelection(e: FormEvent<HTMLSelectElement>) {
        e.preventDefault();
        const v = e.currentTarget.value;
        stores.midi.useOutput(v, true);
    }

    function portLabel(port: Port): string {
        let s = port.name;
        if (port.deviceVersion) {
            s = s + ' (' + port.deviceVersion;
            if (port.deviceSerial) {
                s = s + ' SN ' + port.deviceSerial;
            }
            s = s + ')';
        }
        return s;
    }

    const midi_ok = true;

    return (
        <div className={`ports-row ${midi_ok ? 'midi-ok' : 'midi-ko'}`}>
            <div>
                <span>MIDI input: </span>
                <select 
                    onChange={handleInSelection} 
                    value={stores.midi.inputInUse} 
                    className={`${stores.midi.inputInUse ? '' : 'bg-warning'} ${inputColor}`}
                >
                    <option value="">select MIDI input...</option>
                    {Object.entries(stores.midi.inputs).map(([id, port]) => (
                        <option key={id} value={port.id}>{portLabel(port)}</option>
                    ))}
                </select>
            </div>
            <div>
                <span>MIDI output: </span>
                <select 
                    onChange={handleOutSelection} 
                    value={stores.midi.outputInUse} 
                    className={`${stores.midi.outputInUse ? '' : 'bg-warning'} ${outputColor}`}
                >
                    <option value="">select MIDI output...</option>
                    {Object.entries(stores.midi.outputs).map(([id, port]) => (
                        <option key={id} value={port.id}>{portLabel(port)}</option>
                    ))}
                </select>
            </div>
        </div>
    );
});