import React from "react";
import "./Help.css";

export const Help = () => {

    return (
        <div className="help main-content p-20" style={{overflow: 'auto'}}>
            <div className="title2">
                IMPORTANT NOTE:
            </div>
            <div className="bold red">
                Your DMC.micro must be updated to firmware v2.04f in order to use this editor.
            </div>
            <div className="mt-10 line-125">
                Latest firmware, update tools, and info are available on our <a href="https://www.disasterareadesigns.com/support">support page</a>.
            </div>
            <div className="title2">
                How to connect your DMC.micro:
            </div>
            <div className="bold red">
                GEN3 (OLED or LED4) Requires USB mode set to MIDI.
            </div>
            <div className="mt-10">
                If you are using USB HOST mode on your controller:
            </div>
            <ol>
                <li>Plug in a USB cable.</li>
                <li>Wait 1 second.</li>
                <li>Hold the left footswitch to enter SETUP.</li>
                <li>The controller will temporarily set itself to USB MIDI.</li>
                <li>Hold the right footswitch to exit setup when finished editing, or power-cycle the controller.</li>
            </ol>
            <div className="mt-10">
                DMC.micro GEN4 users please connect using the USB type-C port.  USB Host setting does not affect editor connection.
            </div>
            <div className="title2">
                How to use the editor:
            </div>
            <div className="mt-10 line-125">
                After connecting your device, give the app a few seconds to recognize the device and read its configuration.
            </div>
            <div className="mt-20 line-125">
                If the editor display the version and SN (serial number) of your device, everything's ok and you can proceed with the configuration.<br />
            </div>
            <div className="mt-5">
                Otherwise :
            </div>
            <ol>
                <li>refresh the editor window in your browser with Cmd-R (Mac) or F5 (Windows.)</li>
                <li>check the MIDI input and MIDI output selection.</li>
                <li>check the USB mode of the DMC.micro (see above.)</li>
                <li>check your cable.</li>
            </ol>
            <div className="mt-20 line-125">
                Each time you modify a parameter, a yellow background will appear to show that the parameter's value has been edited.
                After a few seconds, the new value will be saved in the DMC.micro and the yellow background will disappear.
            </div>
            <div className="mt-5 line-125">
                If a yellow background persists after you have changed a parameter, that means that the editor is unable to write the new value in the DMC.micro.
                In that case please check the MIDI input/output selection, the cable, the USB mode (see above).
            </div>
            <div className="mt-5 line-125">
                Note: you can use the editor without a DMC.micro connected. In that case, after you have set up your configuration and your presets, use
                the Export feature to save your configuration in a file. You can re-import this file later on to update your DMC.micro.
            </div>
            <div className="title2">
                Need help?  Have feedback about the editor?
            </div>
            <div className="mt-10 line-125">
                Please contact us using the <a href="https://www.disasterareadesigns.com/contact">contact page</a>.
            </div>
            <div className="title2">
                Editor Change Log
            </div>
            <div className="mt-10 line-125">
                0.10.15 add dark mode (experimental!)
            </div>
            <div className="mt-10 line-125">
                0.10.14 add new devices, fix auto-reconnect issue
            </div>
            <div className="mt-10 line-125">
                0.10.14 add firmware version check
            </div>
            <div className="mt-10 line-125">
                0.10.13 add header image
            </div>
            <div className="mt-10 line-125">
                0.10.12 show errors for incorrect devices, add new globals for 2.04e
            </div>
            <div className="mt-10 line-125">
                0.10.11 clean up global show / hide for each device
            </div>
            <div className="mt-10 line-125">
                0.10.10 increase timeout on auto-saves!
            </div>
            <div className="mt-10 line-125">
                0.10.9 new URL for Web MIDI error message
            </div>
            <div className="mt-10 line-125">
                0.10.8 Adds support for additional devices for v2.04d
            </div>
            <div className="mt-10 line-125">
                0.10.7 Now hides non-applicable config entries for each device, requires v2.04a or higher
            </div>
            <div className="mt-10 line-125">
                0.10.6 Added screensaver control, requires firmware 2.04a
            </div>
            <div>
                Add control for Auto channel (used to be linked to input channel,) requires v2.04a
            </div>
            <div className="mt-10 line-125">
                0.10.5 Added support for MIDI thru routing, requires firmware 2.04
            </div>
            <div>
                Added reset button, required for Gen4
            </div>
            <div>
                Fix rendering of firmware version number
            </div>
            <div className="mt-10 line-125">
                0.10.3 Added support for Wampler Pantheon, requires firmware 2.03b
            </div>
        </div>
    );
}
