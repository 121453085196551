import { action, makeAutoObservable } from 'mobx';
import { RootStore } from "./index";
import { loadPreferences, savePreferences } from "../utils/preferences";
import React from "react";

export type Tab = "presets" | "global" | "utils" | "custom1" | "custom2" | "lab" | "import-export" | "overview" | "presets-grid" | "help";

export class StateStore {
    stores: RootStore;
    activeTab: Tab;
    activePreset: number = 0;
    lastUserSelectPreset: number;
    importedPresets: number;
    importedConfiguration: number;
    showAllPresets: boolean = false;
    colors: boolean;
    darkMode: boolean | null;
    presetSelectorsRefs: React.RefObject<any>[] = [];
    currentDeviceType: string | undefined = undefined;

    constructor(stores: RootStore) {
        makeAutoObservable(this, {
            stores: false,
            clearImportedPresets: action,
            incImportedPresets: action,
            clearImportedConfiguration: action,
            incImportedConfiguration: action,
            selectPreset: action,
            enableColors: action,
            disableColors: action,
            toggleColors: action,
            setDarkMode: action,
            toggleDarkMode: action,
            applyDarkMode: action,
            setShowAllPresets: action,
            toggleShowAllPresets: action,
            lastUserSelectPreset: false,
            presetSelectorsRefs: false,
            setCurrentDeviceType: action
        });

        this.activeTab = "global";
        this.activePreset = 0;
        this.lastUserSelectPreset = -1;
        this.importedPresets = 0;
        this.importedConfiguration = 0;

        const prefs = loadPreferences();
        this.colors = prefs.colors;
        this.darkMode = prefs.darkMode;

        document.documentElement.setAttribute("colors", this.colors ? "on" : "off");
        this.applyDarkMode();

        this.stores = stores;
    }

    setCurrentDeviceType(deviceType: string | undefined): void {
        this.currentDeviceType = deviceType;
    }

    resetPresetSelectorsRefs() {
        this.presetSelectorsRefs = [];
        for (let i = 0; i <= 106; i++) {
            this.presetSelectorsRefs.push(React.createRef());
        }
    }

    setShowAllPresets(b: boolean) {
        this.showAllPresets = b;
    }

    toggleShowAllPresets() {
        this.showAllPresets = !this.showAllPresets;
    }

    setTab(tab: Tab): void {
        this.activeTab = tab;
    }

    selectPreset(preset: number, scrollIntoView = false): void {
        this.activePreset = preset;
        if (scrollIntoView) {
            try {
                this.presetSelectorsRefs[preset].current.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                });
            } catch (e) {
                // console.log("presetSelectorsRefs not yet initialized");
            }
        } else {
            this.lastUserSelectPreset = preset;
        }
    }

    clearImportedPresets(): void {
        this.importedPresets = 0;
    }

    incImportedPresets(): void {
        this.importedPresets++;
    }

    clearImportedConfiguration(): void {
        this.importedConfiguration = 0;
    }

    incImportedConfiguration(): void {
        this.importedConfiguration++;
    }

    enableColors(): void {
        this.colors = true;
        document.documentElement.setAttribute("colors", "on");
        savePreferences({colors: this.colors, darkMode: this.darkMode});
    }

    disableColors(): void {
        this.colors = false;
        document.documentElement.setAttribute("colors", "off");
        savePreferences({colors: this.colors, darkMode: this.darkMode});
    }

    toggleColors(): void {
        this.colors = !this.colors;
        document.documentElement.setAttribute("colors", this.colors ? "on" : "off");
        savePreferences({colors: this.colors, darkMode: this.darkMode});
    }

    applyDarkMode = () => {
        if (this.darkMode === null) {
            // Follow system preference
            document.documentElement.removeAttribute('dark-mode');
        } else {
            document.documentElement.setAttribute('dark-mode', this.darkMode ? 'on' : 'off');
        }
    }

    setDarkMode = (mode: boolean | null) => {
        this.darkMode = mode;
        this.applyDarkMode();
        savePreferences({ colors: this.colors, darkMode: this.darkMode });
    }

    toggleDarkMode = () => {
        this.darkMode = this.darkMode === null ? true : !this.darkMode;
        this.applyDarkMode();
        savePreferences({ colors: this.colors, darkMode: this.darkMode });
    }
}