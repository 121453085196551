import {observer} from "mobx-react-lite";
import React, { useEffect } from "react";
import GLOBAL_GROUPS from "../model/global-groups.json";
import {groupEnabled} from "../model";
import {Group} from "./Group";
import {ReadConfigurationButton} from "./ReadConfigurationButton";
import {RestartDeviceButton} from "./RestartDeviceButton";
import {stores} from "../stores";
import "./Global.css";

export const Global = observer(() => {
    useEffect(() => {
        const darkModeMediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
        const handleChange = (e: MediaQueryListEvent) => {
            if (stores.state.darkMode === null) {
                stores.state.applyDarkMode();
            }
        };

        darkModeMediaQuery.addEventListener('change', handleChange);

        return () => darkModeMediaQuery.removeEventListener('change', handleChange);
    }, []);

    useEffect(() => {
        stores.state.applyDarkMode();
    }, []);

    return (
        <div className={`global main-content flex ${stores.state.darkMode ? 'dark-mode' : ''}`}>
            <div className="left scroll-y">
                {Object.entries(GLOBAL_GROUPS).filter(([key, group]: any) => groupEnabled(group)).map(([key, group], i) => {
                    return <a href={`#${group.label}`} className={`group-link ${key}`} key={key}>{group.label}</a>;
                })}
                <div className="mt-15 ml-10 global-reread">
                    <ReadConfigurationButton />
                </div>
                <div className="mt-15 ml-10 global-reread">
                    <RestartDeviceButton />
                </div>
            </div>
            <div className="right scroll-y grow">
                <div className="colors-toggle">
                    <button className="text-small" onClick={() => stores.state.toggleColors()}>{stores.state.colors ? "colors off" : "colors on"}</button>
                    <button className="text-small ml-2" onClick={() => stores.state.toggleDarkMode()}>{stores.state.darkMode ? "light mode" : "dark mode"}</button>
                </div>
                {Object.entries(GLOBAL_GROUPS).filter(([key, group], _) => groupEnabled(group)).map(([key, group], i) => {
                    return (
                        <React.Fragment key={key}>
                            <Group group={group} groupKey={key} />
                        </React.Fragment>
                    );
                })}
            </div>
        </div>
    );
});