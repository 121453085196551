import {observer} from "mobx-react-lite";
import React from "react";
import {Parameter} from "./parameter/Parameter";
import {stores} from "../stores";
import DEVICES from "../model/devices.json";
import {getDeviceDisplayStyleOptions} from "../model/displayStyles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import "./Preset.css";

interface PresetDeviceProps {
    deviceLetter: string; // A, B, ...
}

export const PresetDevice = observer(({deviceLetter}: PresetDeviceProps) => {

    //TODO: check deviceLetter validity

    const presetNumber = stores.state.activePreset;
    const deviceNum = stores.memory.getGlobalParamValue(`DEVICE_${deviceLetter}`);
    const channels = stores.memory.getGlobalParamValue("PRST_CHAN");
    const channelOffset = stores.memory.getGlobalParamValue("CHAN_OFFSET");

    let midiChannel;
    let channelBit;
    switch (deviceLetter.toUpperCase()) {
        case 'A':
            channelBit = 1;
            midiChannel = 1 + channelOffset;
            break;
        case 'B':
            channelBit = 2;
            midiChannel = 2 + channelOffset;
            break;
        case 'C':
            channelBit = 4;
            midiChannel = 3 + channelOffset;
            break;
        case 'D':
            channelBit = 8;
            midiChannel = 4 + channelOffset;
            break;
        default:
            channelBit = 128;   // outside of 0..127
            //TODO: log an error
    }

    return (
        <div className="preset-device-wrapper">
            <div className="preset-device-label title2">
                Device {deviceLetter} - {DEVICES[deviceNum]?.name ?? "DEVICE UNKNOWN"} <span className="light-text">(MIDI channel {midiChannel})</span>
            </div>
            {((channels & channelBit) === 0) &&
            <div className="warn-channel-disabled red bold mb-10rem">
                <FontAwesomeIcon icon={faExclamationCircle} /> PRST CHAN {deviceLetter} is not enabled in Global Configuration. No message will be sent to this device.
            </div>}
            <Parameter parameterId={`DEV_${deviceLetter}_BYPASS`} presetNumber={presetNumber} className="parameter mb-10rem" />
            <Parameter parameterId={`DEV_${deviceLetter}_PRG`} presetNumber={presetNumber} type="list" options={getDeviceDisplayStyleOptions(deviceNum)} />
        </div>
    );

});
