import {action, makeAutoObservable} from 'mobx';
import {RootStore} from "./index";
import {SYSEX_END, SYSEX_START} from "../utils/midi";
import {getGlobalParameter, getPresetParameter, GLOBAL, ParameterType, PRESETS} from "../model";
import {string2bytes} from "../utils/arrays";
import {getRightShift, setBits} from "../utils/bitwise";

export type PresetData = number[];

export type ValueState = {
    dirty: boolean;
    saved: boolean;
}

export interface ParamsState {
    [parameterId: string]: ValueState
}

export const GLOBAL_MEMORY = -1;

export const GLOBAL_BYTES = 99; // number of bytes including the start and end of sysex markers
export const PRESET_BYTES = 36; // number of bytes including the start and end of sysex markers
const PRESET_NUMBER_OFFSET = 5;
export const NUMBER_OF_PRESETS_SLOTS = 106;

export class MemoryStore {

    stores: RootStore;

    version: Uint8Array;

    global: number[] = [];
    presets: number[][] = [];

    globalStates: ParamsState;
    presetsStates: ParamsState[];

    constructor(stores: RootStore) {
        makeAutoObservable(this, {
            stores: false,
            setVersion: action,
            initGlobal: action,
            initPresets: action,
            setGlobal: action,
            setPreset: action,
            setValue: action,
            setText: action,
            setPresetName: action,
            setGlobalParamDirty: action,
            setPresetParamDirty: action,
            swapPresets: action,
            copyPreset: action,
            // setDefaultPresetsNames: action, // debug method
            markDirtyGlobalParamsAsSaved: action,
            markDirtyPresetParamsAsSaved: action
        });
        this.stores = stores;
        this.version = this.getDefaultVersion();
        this.initGlobal();
        this.initPresets();
        this.globalStates = this.getDefaultGlobalStates();
        this.presetsStates = this.getDefaultPresetsStates();
    }

    getDefaultVersion(): Uint8Array {
        return new Uint8Array([0, 0, 0, 0, 0, 0]);    //TODO: replace magic number with constant
    }

    setVersion(data: Uint8Array) {
        this.version = data;
    }

    decodeAsVersion(data: Uint8Array) {
        let v = '';
        v += String.fromCharCode(data[0] + 0x30);
        v += '.';
        v += String.fromCharCode(data[1] + 0x30);
        v += String.fromCharCode(data[2] + 0x30);
        if (data[3] === 0) { // if we have a zero for the minor version then don't display char 0x60!
            v += '';
        }
        else {
            v += String.fromCharCode(data[3] + 0x60);
        }
        return v;
    }

    decodeAsDeviceType(data: Uint8Array) {
        if (data[0] === 0x01 && data[1] === 0x01) {
            return "DMC-3XL / DMC-4 Gen3";
        }

        if (data[0] === 0x01 && data[1] === 0x02) {
            return "DMC.micro";
        }

        if (data[0] === 0x01 && data[1] === 0x03) {
            return "DMC.micro PRO";
        }

        if (data[0] === 0x01 && data[1] === 0x04) {
            return "DMC.micro Gen4";
        }

        if (data[0] === 0x02 && data[1] === 0x05) {
            return "DPC-5 Gen3";
        }
        
        if (data[0] === 0x02 && data[1] === 0x08) {
            return "DPC-8 Gen3";
        }

        if (data[0] === 0x02 && data[1] === 0x02) {
            return "DPC.micro Gen3";
        }

        if (data[0] === 0x02 && data[1] === 0x03) {
            return "DPC.micro Gen4";
        }

        if (data[0] === 0x03 && data[1] === 0x02) {
            return "micro.clock";
        }

        if (data[0] === 0x03 && data[1] === 0x03) {
            return "micro.clock Gen4";
        }

        if (data[0] === 0x03 && data[1] === 0x01) {
            return "SMARTClock Gen3";
        }
    }

    /**
     *
     * First two bytes of ID should be 0x01, 0x02, next four are FW version
     */
    getDecodedVersion() {
        return this.decodeAsVersion(this.version.slice(2,6));
    }

    getDeviceType() {
        return this.decodeAsDeviceType(this.version.slice(0, 2));
    }

    getSerial() {
        return this.getText(getGlobalParameter("SN"));
    }

    //=============================================================================================

    getDefaultGlobalStates(dirty=false, saved=false): ParamsState {
        return Object.fromEntries(Object.keys(GLOBAL).map((key: string) => {
            return [key, {dirty, saved}]
        }));
    }

    getDefaultPresetsStates(dirty=false, saved=false): ParamsState[] {
        let a = [];
        for (let i=0; i<NUMBER_OF_PRESETS_SLOTS; i++) {
            a.push(Object.fromEntries(Object.keys(PRESETS).map((key: string) => {
                return [key, {dirty, saved}]
            })));
        }
        return a;
    }

    markDirtyGlobalParamsAsSaved(): void {
        Object.values(this.globalStates).forEach(
            (state: ValueState) => {
                if (state.dirty) {
                    state.saved = true;
                    state.dirty = false;
                }
            }
        );
    }

    markDirtyPresetParamsAsSaved(presetNumber: number): void {
        Object.values(this.presetsStates[presetNumber]).forEach(
            (state: ValueState) => {
                if (state.dirty) {
                    state.saved = true;
                    state.dirty = false;
                }
            }
        );
    }

    isGlobalParamDirty(parameterId: string): boolean {
        return this.globalStates[parameterId].dirty;
    }

    isGlobalParamSaved(parameterId: string): boolean {
        return this.globalStates[parameterId].saved;
    }

    isPresetParamDirty(parameterId: string, presetNumber: number): boolean {
        if (!this.presetsStates[presetNumber]) return false;
        return this.presetsStates[presetNumber][parameterId].dirty;
    }

    setGlobalParamDirty(parameterId: string) {
        this.globalStates[parameterId].dirty = true;
        this.globalStates[parameterId].saved = false;
    }

    setPresetParamDirty(parameterId: string, presetNumber: number) {
        this.presetsStates[presetNumber][parameterId].dirty = true;
        this.presetsStates[presetNumber][parameterId].saved = false;
    }

    isPresetParamSaved(parameterId: string, presetNumber: number): boolean {
        if (!this.presetsStates[presetNumber]) return false;
        return this.presetsStates[presetNumber][parameterId].saved;
    }

    //=============================================================================================

    initGlobal() {
        this.global = new Array(GLOBAL_BYTES);
        this.global.fill(0);
        this.global[0] = SYSEX_START;
        this.global[GLOBAL_BYTES - 1] = SYSEX_END;
        // Object.values(MODEL.groups).forEach((group: any) => group.params.forEach((param: any) => a[param.offset] = param.default));

        this.global[2] = 0x02;
        this.global[3] = 0x17;
        this.global[4] = 0x7F;

        for (let param of Object.values(GLOBAL)) {
            this.global[param.offset] = param.default;
        }
    }

    setGlobal(data: number[]) {
        this.global = data.slice();
        this.globalStates = this.getDefaultGlobalStates();
    }

    getGlobalParamValue(parameter: string|ParameterType) {

        let param: ParameterType;
        if (typeof parameter === "string") {
            param = GLOBAL[parameter];
        } else {
            param = parameter;
        }

        if (param) {
            // return this.global[param.offset];

            let value = this.global[param.offset];
            if (param.hasOwnProperty('mask')) {
                const rs = getRightShift(param.mask);
                value = (value & param.mask) >> rs;
            } else {
                value = value & 0x7F;
            }

            if (param.hasOwnProperty('MSB')) {
                // we assume that the LSB uses 7 bits.
                value = value + ((this.global[param.MSB.offset] & param.MSB.mask) << 7);
            }

            if (param.hasOwnProperty('scale')) {
                 value = value * param.scale;
             }

            return value;

        } else {
            console.warn("getGlobalParamValue: param not found", param);
            return 0;
        }
    }

    //=============================================================================================

    getDefaultPreset(presetNumber = 0): number[] {

        const a = new Array(PRESET_BYTES);
        a.fill(0);
        a[0] = SYSEX_START;
        a[PRESET_BYTES - 1] = SYSEX_END;

        // sysex example for a preset:
        // F0 00 02 17 7D 00 00 00 30 30 2E 4E 45 57 20 20 00 04 01 00 04 02 00 04 18 00 04 20 78 00 00 00 00 00 00 F7
        //                ^^--preset number

        a[2] = 0x02;
        a[3] = 0x17;
        a[4] = 0x7D;

        for (let param of Object.values(PRESETS)) {
            a[param.offset] = param.default;
        }

        a[PRESET_NUMBER_OFFSET] = presetNumber;

        // DEBUG:
        // a[8] = 'A'.charCodeAt(0) + presetNumber;
        // a[9] = 'A'.charCodeAt(0) + presetNumber;
        // a[10] = 'A'.charCodeAt(0) + presetNumber;

        return a;
    }

    initPresets() {
        this.presets = new Array(NUMBER_OF_PRESETS_SLOTS);
        for (let i=0; i<=NUMBER_OF_PRESETS_SLOTS; i++) {
            this.presets[i] = this.getDefaultPreset(i);
            // this.presetsNames[i] = '';
            // this.presetsParamsStates[i] = this.getDefaultPresetParamsStates();
        }
    }

    //DEBUG:
    // setDefaultPresetsNames() {
    //     for (let i=0; i<=NUMBER_OF_PRESETS_SLOTS; i++) {
    //         this.setPresetName(`preset${i}`, i)
    //     }
    // }

    copyPreset(from: number, to: number) {
        if (from === to) return;
        if (from < 0) return;
        if (to < 0) return;

        const a = this.presets[from].slice();
        a[PRESET_NUMBER_OFFSET] = to;
        this.presets[to] = a;
    }

    swapPresets(number1: number, number2: number) {
        if (number1 === number2) return;
        if (number1 < 0) return;
        if (number2 < 0) return;

        const p1 = this.presets[number1].slice();
        const p2 = this.presets[number2].slice();

        p1[PRESET_NUMBER_OFFSET] = number2;
        this.presets[number2] = p1;

        p2[PRESET_NUMBER_OFFSET] = number1;
        this.presets[number1] = p2;
    }

    setPreset(data: PresetData) {
        const presetNumber = data[5];
        this.presets[presetNumber] = data;
        this.presetsStates = this.getDefaultPresetsStates();
    }

    getPresetParamValue(presetNumber: number, parameter: string|ParameterType) {
        let param: ParameterType;
        if (typeof parameter === "string") {
            param = PRESETS[parameter];
        } else {
            param = parameter;
        }
        if (param) {

            let value = this.presets[presetNumber][param.offset];
            if (param.hasOwnProperty('mask')) {
                const rs = getRightShift(param.mask);
                value = (value & param.mask) >> rs;
            } else {
                value = value & 0x7F;
            }

            if (param.hasOwnProperty('MSB')) {
                // we assume that the LSB uses 7 bits.
                value = value + ((this.presets[presetNumber][param.MSB.offset] & param.MSB.mask) << 7);
            }
            return value;

        } else {
            console.warn("getGlobalParamValue: param not found", param);
            return 0;
        }
    }

    //=============================================================================================

    getValue(parameter: ParameterType, presetNumber = -1): number {
        if (parameter.type === "global") {
            return this.getGlobalParamValue(parameter);
        } else {
            if (presetNumber >= 0) {
                return this.getPresetParamValue(presetNumber, parameter);
            } else {
                console.error("getValue: invalid preset number for parameter", parameter, typeof parameter, presetNumber, typeof presetNumber);
                return 0;
            }
        }
    }

    setValue(parameter: ParameterType, value: number, presetNumber = GLOBAL_MEMORY) {

        if (parameter.type === "global") {

            if (parameter.hasOwnProperty('scale')) {
                 value = Math.round(value / parameter.scale);
             }

            if (parameter.hasOwnProperty('mask')) {
                this.global[parameter.offset] = setBits(this.global[parameter.offset], value, parameter.mask, 7);
            } else {
                this.global[parameter.offset] = value & 0x7F;
            }

            if (parameter.hasOwnProperty('MSB')) {
                // we assume that the LSB uses 7 bits.
                let msb = (value & (parameter.MSB.mask << 7)) >> 7;
                let invert_mask = (~parameter.MSB.mask >>> 0) & 0x7F;   // convert an unsigned integer to a signed integer with the unsigned >>> shift operator
                let current_msb_byte = this.global[parameter.MSB.offset];
                this.global[parameter.MSB.offset] = (current_msb_byte & invert_mask) | msb;
            }

            this.setGlobalParamDirty(parameter.id);

        } else {
            if (presetNumber >= 0) {

                if (parameter.hasOwnProperty('mask')) {
                    this.presets[presetNumber][parameter.offset] = setBits(this.presets[presetNumber][parameter.offset], value, parameter.mask, 7);
                } else {
                    this.presets[presetNumber][parameter.offset] = value & 0x7F;
                }

                if (parameter.hasOwnProperty('MSB')) {
                    let msb = (value & (parameter.MSB.mask << 7)) >> 7;
                    let invert_mask = (~parameter.MSB.mask >>> 0) & 0x7F;   // convert an unsigned integer to a signed integer with the unsigned >>> shift operator
                    let current_msb_byte = this.presets[presetNumber][parameter.MSB.offset];
                    this.presets[presetNumber][parameter.MSB.offset] = (current_msb_byte & invert_mask) | msb;
                }

                this.setPresetParamDirty(parameter.id, presetNumber);

            } else {
                console.error("getValue: invalid preset number for parameter", parameter, presetNumber);
                // return 0;
            }
        }
    }

    //=============================================================================================

    getText(parameter: ParameterType, presetNumber = GLOBAL_MEMORY): string {

        // console.log("getText", presetNumber, parameter);

        let text = '';
        const len = parameter.len;
        if (!len) {
            console.error("Invalid len in parameter definition", parameter);
            return '';
        }
        if (parameter.type === "global") {
            for (let i=0; i<len; i++) {
                let c = this.global[parameter.offset + i];
                if (c > 0) text += String.fromCharCode(c);
            }
        } else {
            if (presetNumber >= 0) {
                for (let i=0; i<len; i++) {
                    let c = this.presets[presetNumber][parameter.offset + i];
                    if (c > 0) text += String.fromCharCode(c);
                }
            } else {
                console.error("getValue: invalid preset number for parameter", parameter, presetNumber);
            }
        }
        // console.log(`getText return |${text}|`);
        return text;
    }

    setText(parameter: ParameterType, text: string, presetNumber = GLOBAL_MEMORY) {

        // console.log("setText", presetNumber, text);

        const len = parameter.len;
        if (!len) {
            console.error("Invalid len in parameter definition", parameter);
            return '';
        }
        let bytes = string2bytes(text, len);
        if (bytes.length === 0) return;

        // console.log("setText bytes", presetNumber, hs(bytes));

        if (parameter.type === "global") {
            for (let i=0; i<len; i++) {
                this.global[parameter.offset + i] = bytes[i];
                this.setGlobalParamDirty(parameter.id);
            }
        } else {
            if (presetNumber >= 0) {
                for (let i=0; i<len; i++) {
                    // console.log("setText preset set", presetNumber, parameter.offset + i, bytes[i]);
                    this.presets[presetNumber][parameter.offset + i] = bytes[i];
                    this.setPresetParamDirty(parameter.id, presetNumber);
                }
            } else {
                console.error("getValue: invalid preset number for parameter", parameter, presetNumber);
            }
        }
    }

    //=============================================================================================
    // utils and shortcuts

    getPresetName(presetNumber: number) {
        return this.getText(getPresetParameter("NAME"), presetNumber);
    }

    setPresetName(name: string, presetNumber: number) {
        this.setText(getPresetParameter("NAME"), name, presetNumber);
    }

}
