import React from "react";
import { observer } from "mobx-react-lite";
import { stores } from "../stores";
import { faCheck, faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const validDeviceTypes = ["DMC.micro", "DMC.micro PRO", "DMC.micro gen4", "DMC.micro Gen4"];

const getEditorLink = (deviceType: string | undefined): string | null => {
    switch (deviceType) {
        case "DMC.micro":
        case "DMC.micro PRO":
        case "DMC.micro gen4":
        case "DMC.micro Gen4":
            return "https://disasterarea.io/dmcmicro";
        case "DPC-5 Gen3":
            return "https://disasterarea.io/dpc5";
        case "DPC.micro Gen3":
        case "DPC.micro Gen4":
            return "https://disasterarea.io/dpcmicro";
        case "DPC-8EZ Gen3":
            return "https://disasterarea.io/dpc";
        case "micro.clock":
        case "micro.clock Gen4":
        case "SMARTClock Gen3":
            return "https://disasterarea.io/microclock";
        default:
            return null;
    }
};

const getMinimumFirmwareVersion = (deviceType: string): string => {
    switch (deviceType) {
        case "DMC.micro":
        case "DMC.micro PRO":
        case "DMC.micro gen4":
        case "DMC.micro Gen4":
            return "2.04f";
        default:
            return "0.00";
    }
};

const compareVersions = (v1: string, v2: string): number => {
    const normalize = (v: string): [number, number, string] => {
        const parts = v.split('.');
        const major = parseInt(parts[0]) || 0;
        const minorPart = parts[1] || '';
        const minorNumber = parseInt(minorPart) || 0;
        const minorLetter = minorPart.replace(/[0-9]/g, '');
        return [major, minorNumber, minorLetter];
    };

    const [major1, minor1, letter1] = normalize(v1);
    const [major2, minor2, letter2] = normalize(v2);

    if (major1 !== major2) return major1 - major2;
    if (minor1 !== minor2) return minor1 - minor2;
    return letter1.localeCompare(letter2);
};

export const DeviceInfo = observer(() => {
    const deviceType = stores.memory.getDeviceType();
    const isValidDeviceType = deviceType !== undefined && validDeviceTypes.includes(deviceType);
    const firmwareVersion = stores.memory.getDecodedVersion();
    const minimumFirmwareVersion = deviceType ? getMinimumFirmwareVersion(deviceType) : "0.00";
    const isFirmwareOutdated = compareVersions(firmwareVersion, minimumFirmwareVersion) < 0;

    const getSupportLink = (content: React.ReactNode, isRed: boolean = false) => (
        <a 
            href="https://disasterareadesigns.com/support" 
            target="_blank" 
            rel="noopener noreferrer"
            style={isRed ? { color: 'red', textDecoration: 'none' } : undefined}
        >
            {content}
        </a>
    );

    const getErrorMessage = () => {
        if (!stores.midi.deviceFound) {
            return (
                <span>
                    Unable to detect the DMC.micro. Check the MIDI input & output selections above and refresh the browser window. {getSupportLink("Get support")}
                </span>
            );
        } else if (deviceType === undefined) {
            return (
                <span>
                    Unable to determine device type. Please check your connection and try again. {getSupportLink("Get support")}
                </span>
            );
        } else if (isFirmwareOutdated) {
            return getSupportLink(
                `Firmware v${firmwareVersion} is outdated. Click here to update to v${minimumFirmwareVersion} or higher.`,
                true
            );
        } else {
            const editorLink = getEditorLink(deviceType);
            if (editorLink) {
                return (
                    <span>
                        Invalid device type: {deviceType} - <a href={editorLink} target="_blank" rel="noopener noreferrer">click here to load the correct editor</a>.
                    </span>
                );
            } else {
                return (
                    <span>
                        Invalid device type: {deviceType}. {getSupportLink("Get support")}
                    </span>
                );
            }
        }
    };

    return (
        <div className="row">
            {stores.midi.deviceFound && isValidDeviceType && !isFirmwareOutdated && (
                <div className="green">
                    <FontAwesomeIcon icon={faCheck} /> {deviceType} v{firmwareVersion}, SN {stores.memory.getSerial()}
                </div>
            )}
            {(!stores.midi.deviceFound || !isValidDeviceType || isFirmwareOutdated) && (
                <div className="red">
                    <FontAwesomeIcon icon={faExclamationCircle} /> {getErrorMessage()}
                </div>
            )}
        </div>
    );
});